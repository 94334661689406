import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styles from './ProductDownloadPage.scss';

import UnitList from '../../../components/UnitList/UnitList.js';
import MultipleContentFrameContainer from '../../../components/MultipleContentFrameContainer/MultipleContentFrameContainer.js';

import { getProductStructureRequest } from '../../../redux/actions/productStructure.js';
import {
  addOfflineProducts,
  addOfflineProfiles,
  addOfflineUnits
} from '../../../redux/actions/offlineContentPlayer.js';
import { EPS_ASSETS_BASE_ELT_MISC } from '../../../../sharedNodeBrowser/constants';
import { getPlatformBaseUrl } from '../../../globals/envSettings.js';

function ProductDownloadPage({
  units = [],
  storedOfflineUnits,
  getProductStructureRequestAction,
  addOfflineProductsAction,
  addOfflineProfilesAction,
  addOfflineUnitsAction
}) {
  const [contentCode, setContentCode] = useState('');
  const [productIsbn, setProductIsbn] = useState('');
  const [productTitle, setProductTitle] = useState('');
  const [activities, setActivities] = useState([]);
  const [iframeData, setIframeData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [data, setData] = useState({});
  const unitId = useRef(null);

  useEffect(() => {
    const handleMessage = event => {
      if (event.data.type === 'PRODUCT_DOWNLOAD_CONTENT_CODE' && event.origin === getPlatformBaseUrl('hub')) {
        setContentCode(event.data.payload.contentCode);
        if (event.data.payload.cptContent) {
          setProductIsbn(event.data.payload.cptContent.isbn);
        }
        setProductTitle(event.data.payload.productTitle);
        setUserId(event.data.payload.userId);
        setData(event.data.payload.cptContent);
        setFirstName(event.data.payload.firstName);
        setLastName(event.data.payload.lastName);
      }
    };

    window.addEventListener('message', handleMessage);
    window.parent.postMessage({ type: 'IFRAME_READY' }, '*');

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (contentCode) {
      getProductStructureRequestAction({ 'content-code': contentCode, depth: '1' });
    }
  }, [contentCode]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const newProductKey = Object.keys(data.offlineProduct)[0];
      const newProduct = { [newProductKey]: Object.values(data.offlineProduct)[0] };

      addOfflineProductsAction([newProduct]);
    }
  }, [data]);

  // TODO: refactor this logic to decrease the number of re-renders
  useEffect(() => {
    if (activities && activities.length > 0) {
      const extractResourceIdAndUrlFromActivity = activities.map(activity => {
        const url = activity.url;
        if (!url || url.length === 0) {
          return {
            message: 'Invalid url'
          };
        }
        const regex = /resources\/(.*?)\/index\.html/;
        const match = url.match(regex);
        const resourceId = match ? match[1] : '';
        let portString = '';
        if (window.location.port !== 80) {
          portString = `:${window.location.port}`;
        }

        return {
          src: `${window.location.protocol}//${window.location.hostname}${portString}${activity.url}`,
          title: resourceId,
          activityId: resourceId,
          unitId: unitId.current
        };
      });
      setIframeData(extractResourceIdAndUrlFromActivity);
    }
  }, [activities]);

  const filteredUnits = units
    .filter(item => item.contentCode === contentCode)
    .filter(item => item.isbn === productIsbn)
    .map(unit => {
      const matchOfflineUnits = storedOfflineUnits.filter(offlineUnit => offlineUnit.id === unit.uId);
      const cptContentUnit = data.contents.children[unit.uId];
      const userIdsArray = matchOfflineUnits.map(offlineUnit => offlineUnit.userId);

      return {
        ...unit,
        hasAnyActivityWithPrintView: cptContentUnit.hasAnyActivityWithPrintView,
        printViewImages: cptContentUnit.printViewImages,
        activities: cptContentUnit.activitiesUrls,
        userId: userIdsArray.length > 0 ? userIdsArray : null
      };
    });

  const storeUnit = unit => {
    const { uId, isbn, levelName } = unit;

    const currentDate = new Date()
      .toISOString()
      .replace('T', ' ')
      .replace('Z', '');

    const unitToStore = {
      id: uId,
      isbn,
      name: levelName,
      contentCode,
      description: productTitle,
      expire_date: data.offlineProduct[contentCode].expires,
      added_date: currentDate,
      userId,
      printViewImages: unit.printViewImages,
      activities: unit.activities
    };

    addOfflineUnitsAction([unitToStore]);
  };

  const storeProfile = () => {
    const newProfile = {
      [userId]: {
        firstName,
        lastName: lastName[0],
        token: data.offlineProduct[contentCode].userData[userId].launchToken,
        token_expiry: data.offlineProduct[contentCode].expires,
        products: [contentCode]
      }
    };

    addOfflineProfilesAction([newProfile]);
  };

  const extractActivitiesFromUnit = (uId, content) => {
    const unitActivities = [];

    const traverse = node => {
      if (node.activities && Array.isArray(node.activities)) {
        unitActivities.push(...node.activities);
      }

      if (node.children && typeof node.children === 'object') {
        Object.values(node.children).forEach(child => traverse(child));
      }
    };

    if (content.children && content.children[uId]) {
      traverse(content.children[uId]);
    }

    return unitActivities;
  };

  const handleDownloadCallback = async (selectedUnitId, isPrintViewSelected) => {
    if (!window.navigator.onLine) {
      return 0;
    }

    const foundUnit = filteredUnits.find(unit => unit.uId === selectedUnitId);

    unitId.current = foundUnit.uId;
    storeUnit(foundUnit);
    storeProfile();
    const productContents = data.offlineProduct[contentCode].product.contents;
    const unitActivities = extractActivitiesFromUnit(foundUnit.uId, productContents);

    setActivities(unitActivities);

    let portString = '';
    if (window.location.port !== 80) {
      portString = `:${window.location.port}`;
    }

    const cache = await caches.open('printView');
    if (isPrintViewSelected) {
      const imagesUrl = foundUnit.printViewImages.map(
        img => `${window.location.protocol}//${window.location.hostname}${portString}${EPS_ASSETS_BASE_ELT_MISC}/${img}`
      );

      await cache.addAll(imagesUrl);
    }

    return 1;
  };

  return (
    <div className={styles.container}>
      <UnitList units={filteredUnits} downloadButton userId={userId} downloadCallback={handleDownloadCallback} />

      {iframeData && iframeData.length > 0 ? (
        <div className={styles.activitiesIframeContainer}>
          <MultipleContentFrameContainer
            frames={iframeData}
            customClassName={styles.activitiesIframe}
            currentFrameIndex={1}
            loadingMode="preload-all"
            persistenceMode="presist-all-frames"
          />
        </div>
      ) : null}
    </div>
  );
}

ProductDownloadPage.propTypes = {
  units: PropTypes.array.isRequired,
  storedOfflineUnits: PropTypes.array.isRequired,
  getProductStructureRequestAction: PropTypes.func.isRequired,
  addOfflineProductsAction: PropTypes.func.isRequired,
  addOfflineProfilesAction: PropTypes.func.isRequired,
  addOfflineUnitsAction: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ productStructure, offlineContentPlayer }) => ({
      units: productStructure.productStructure,
      storedOfflineUnits: offlineContentPlayer.units
    }),
    {
      getProductStructureRequestAction: getProductStructureRequest,
      addOfflineProductsAction: addOfflineProducts,
      addOfflineProfilesAction: addOfflineProfiles,
      addOfflineUnitsAction: addOfflineUnits
    }
  )
)(ProductDownloadPage);
